@import 'StyleVariables';
@import 'StyleFonts';

html {
  box-sizing: border-box;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: normal;
}

body {
  background-color: $white;
  min-height: 100%;

  font-family: 'Maax Akili' !important;
  @extend %body-font;
  @extend %dark-primary;
}

#root {
  height: 100%;
  min-height: 100%;
}

#root > div, #root > div > iframe {
  height: 100%;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
