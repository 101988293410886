@import 'StyleVariables';
@import 'StyleFonts';

.error_container {
    display: flex;
    flex-grow: 1;
    padding-top: 100px;
    align-items: center;
    flex-direction: column;

    @extend %body-font;
    text-align: center;

    a {
        color: $brand-v2;
        text-decoration: none;
        @extend %body-2-font;
    }

    button {
        margin-top: 42px;
        text-transform: none !important;
        max-width: 325px;
    }

    svg {
        margin-bottom: 42px;
    }
}

.content_layout {
    @extend %body-font;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
    
    h1 {
        @extend %display-font;
    }
    div {
        display: flex;
        width: 100%;
    }
}