@import 'StyleVariables';

.layoutBlend {
  height: unset !important;
  min-height: unset !important;
  margin: 10px;

  @media only screen and (max-width: 992px) {    
    margin: 10px 0; 
  }
}
.container {
  background-color: $instruction;
  border-radius: 6px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 885px;
  min-width: 380px;

  @media only screen and (max-width: 992px) {    
    width: 100% !important;
    max-width: calc(380px + 16px *2);
  }

  .content {
    @extend %caption-font;
    text-align: center;
    display: flex;
    .messageContainer {
      margin-left: 20px;
      color: $banner-text;
      @extend %body-font;
      text-align: left;
      
      white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
      white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
      white-space: -pre-wrap;                 /* Opera 4-6 */
      white-space: -o-pre-wrap;               /* Opera 7 */
      white-space: pre-wrap;                  /* CSS3 */
      word-wrap: break-word;                  /* Internet Explorer 5.5+ */
      word-break: break-word;
      white-space: pre-wrap;      
    }
  }
  .contentCloseIcon {
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: end !important;
    .bannerIcon {
      align-items: unset;
    }
  }
}