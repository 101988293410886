@import 'StyleVariables';

.primaryButton:disabled {
  cursor: default;
  text-transform: uppercase;
  font-weight: 700;
  border-color: $brand-v2;
  border-radius: 74px;
  height: 40px;
  @include getContentSize(2, 3, 3, 3, 3);
}
.primaryButton:focus {
  outline: none;
  cursor: default;
}
.primaryButton {
  @extend %primary-colors;
  background-color: $brand-v2;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  outline: 0;
  border-radius: 74px;
  height: 40px;
  @include getContentSize(2, 3, 3, 3, 3);
}
.buttonContentLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.loadingIcon {
  width: 28px;
  height: 28px;
  background-image: url('./assets/WhiteSpinner.gif');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
